import React from "react"
import { css } from "@emotion/react"
import { size, color } from "./theme"
import mq from '../styles/mediaquerys';
import Link from "../../link";
import Info from "../assets/svg/info.svg"
import Warning from "../assets/svg/warning.svg"
import Lock from "../assets/svg/lock.svg"
import Close from "../assets/svg/close.svg"
import Flags from '../../../data/raw/flags';

import PartnerLogo from '../../main/logo-partner';
import StartupLogo from '../../main/logo-startup';

export const Title = (props) => {
    return(
        <section>
            <p css={css`
                margin-bottom: .5rem;
                font-weight: 500;
                opacity: .4;
            `}>{props.subtitle}</p>
            <h2 css={css`
                font-size: 2.5rem;
            `}>{props.title}</h2>
        </section>
    )
}

export const wrapper = css`
    max-width: ${size.maxWidth};
    margin: 0 auto;
    padding: 0 1rem;
`

export const pretitle = css`
    text-transform: uppercase;
    font-size: 0.715rem;
    letter-spacing: 0.12rem;
    font-weight: 700;
    margin: 0;
` 

export const small = css`
    font-size: 0.65rem;
    opacity: 0.6;
    margin: 0 0 .5rem;
`

export const ArrowLink = ({ className, url, text }) => {
    return (
      <Link
        className={className}
        to={url}
        css={css`
          color: white;
          margin-top: 3em;
          display: block;
          text-decoration: none;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 0.85rem;
          display: flex;
          align-items: center;
        `}
      >
        { text }
        <svg
          css={css`
            height: 0.8em;
            width: auto;
            margin-left: 0.5em;
            stroke: white;
          `}
          viewBox="0 0 10 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L8 6.5L1 12" strokeWidth="2" />
        </svg>
      </Link>
    )
};

export const ArrowLinkBtn = ({ className, text, ...rest }) => {
    return (
      <p
        className={className}
        {...rest}
        css={css`
          color: white;
          margin-top: 3em;
          display: block;
          text-decoration: none;
          font-weight: 700;
          text-transform: uppercase;
          font-size: 0.85rem;
          display: flex;
          align-items: center;
          cursor: pointer;
        `}
      >
        { text }
        <svg
          css={css`
            height: 0.8em;
            width: auto;
            margin-left: 0.5em;
            stroke: white;
          `}
          viewBox="0 0 10 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L8 6.5L1 12" strokeWidth="2" />
        </svg>
      </p>
    )
};

export const TitleHeader = ({className, title, subtitle, icon, transparent, inline, size, fullWidth, dark, number}) => {
    return (
      <>
        <div
          className={className}
          css={[
            inline ? "" : wrapper,
            css`
              padding-top: 7em;
            `,
          ]}
        >
          <div
            css={css`
              display: flex;
              align-items: baseline;
              margin-bottom: 1rem;
            `}
          >
            {icon !== "none" ? (
              <div
                css={css`
                  padding: 0.5em 0.5rem;
                  background: ${dark ? "white" : color.main_dark};
                  border-radius: 5px;
                  line-height: 1em;
                  margin-right: 0.75em;
                  min-width: ${number && !icon ? "2.25em" : "auto"};
                  display: ${number && !icon ? "flex" : "block"};
                  justify-content: ${number && !icon ? "center" : "unset"};
                  svg {
                    height: 1rem;
                    width: auto;
                    line-height: 1em;
                    stroke: ${dark ? color.main_dark : "white"};
                  }
                  p {
                    margin-bottom: 0;
                    color: ${dark ? color.main_dark : "white"};
                    font-weight: 700;
                  }
                `}
              >
                {number && !icon ? (<p>{number}</p>) : icon}
              </div>
            ) : ''}
            <h2
              css={css`
                font-weight: 700;
                font-size: ${size ? size + "em" : "1.5rem"};
                color: ${dark ? "white" : color.main_dark};
                line-height: ${size ? (size * 1.2) + "em" : "1.3em"};
                text-indent: -0.05em;
                margin-bottom: 0em;
              `}
            >
              {title}
            </h2>
          </div>
          {subtitle ? (
            <div
              css={css`
                font-size: 0.75em;
                max-width: 35rem;
                width: ${fullWidth ? "100%" : "90%"};
                padding: 0 0 1rem;
                /* margin-bottom: 2em; */
                background: ${transparent ? "none" : "white"};
                p, a, h1, h2, h3 {
                  padding-bottom: 0.5em;
                  margin: 0;
                  color: ${dark ? "white" : color.main_dark};
                }
                h1, h2, h3 {
                  font-size: 1em;
                  line-height: inherit;
                  padding-bottom: 0;
                }
              `}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </>
    )
};

export const Banner = ({ className, type, content, children, icon}) => {
    return (
      <div css={[wrapper, css`
        margin-bottom: .5em;
      `]}>
        <div
          className={className}
          css={css`
            background: ${color.accent_light};
            border-radius: 5px;
            padding: 1rem;
            max-width: 35rem;
          `}
        >
          <div css={css`
            display: flex;
            align-items: flex-start;
          `}>
            <div
              css={css`
                line-height: 1em;
                margin-right: 0.75em;
                svg {
                  height: 1rem;
                  width: auto;
                  line-height: 1em;
                  stroke: ${color.main_dark};
                }
              `}
            >
              {type === "info" ? <Info /> : type === "warning" ? <Warning /> : type === "lock" ? <Lock /> : icon ? icon : ""}
            </div>
            <div
              css={css`
                p {
                  font-size: 0.75em;
                  margin: 0;
                  padding-bottom: .5em;
                }
                a, p {
                    color: ${color.main_dark};
                    text-decoration: none;
                }
              `}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </div>
          { children }
        </div>
      </div>
    )
};


export const IconButton = ({className, name, url, icon, getProps, white, ...rest}) => {
  return (
    <Link
      className={className}
      to={url}
      getProps={getProps}
      activeClassName="active"
      {...rest}
      css={css`
        color: ${white ? color.main_dark : "white" };
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: 500;
        position: relative;
        padding: 0.675rem 1rem;
        border: none;
        background: none;
        svg {
          height: 0.9em;
          width: auto;
          margin-right: 0.5rem;
          fill: none;
          stroke: ${white ? color.main_dark : "white"};
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 5px;
          background: ${white ? color.main_light : color.cta_green};
          border: ${white ? "1px solid" + color.accent_light : "none"};
          z-index: -1;
        }
      `}
    >
      {icon}
      {name}
    </Link>
  )
};

export const IconButtonOff = ({className, name, icon, ...rest}) => {
  return (
    <div
      className={className}
      {...rest}
      css={css`
        color: white;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: 500;
        position: relative;
        padding: 0.675rem 1rem;
        border: none;
        background: none;
        svg {
          height: 0.9em;
          width: auto;
          margin-right: 0.5rem;
          fill: none;
          stroke: white;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 5px;
          background: ${color.cta_green};
          z-index: -1;
        }
      `}
    >
      {icon}
      {name}
    </div>
  )
};

export const IconButtonBtn = ({className, name, icon, ...rest}) => {
  return (
    <button
      className={className}
      {...rest}
      css={css`
        color: white;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: 500;
        position: relative;
        padding: 0.675rem 1rem;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        svg {
          height: 0.9em;
          width: auto;
          margin-right: 0.5rem;
          fill: none;
          stroke: white;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 5px;
          background: ${color.cta_green};
          z-index: -1;
        }
      `}
    >
      {icon}
      {name}
    </button>
  )
};

export const IconButtonProhibOverflow = ({className, name, url, icon, getProps}) => {
  return (
    <Link
      className={className}
      to={url}
      getProps={getProps}
      activeClassName="active"
      css={css`
        color: white;
        text-decoration: none;
        font-size: 0.8rem;
        font-weight: 500;
        position: relative;
        padding: 0.675rem 1rem;
        border: none;
        background: none;
        svg {
          height: 0.9em;
          width: auto;
          margin-right: 0.5rem;
          fill: none;
          stroke: white;
        }
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          border-radius: 5px;
          background: ${color.cta_green};
          z-index: -1;
        }
      `}
    >
      {icon}
      <p css={css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `}>{name}</p>
    </Link>
  )
};


export const PeopleDisclaimer = ({className}) => {
  return (
    <div className={className}
      css={[
        css`
          color: ${color.main_dark};
          font-size: 0.65em;
          max-width: 40rem;
          width: 100%;
          padding-bottom: 5em;
          opacity: 0.75;
          p {
            margin-bottom: 0.5em;
          }
          p:last-of-type {
            margin-bottom: 0;
          }
          a {
            white-space: nowrap;
            color: ${color.main_dark};
          }
        `,
      ]}
    >
      <p>
        <b>Content Disclaimer</b>
      </p>
      <p>
        All information listed on this page have been submitted to Plug & Play
        Germany GmbH via a web form. The site owner can not guarantee for the
        validity and correctness of facts, information or assumptions provided
        by the submitter. Presented perspectives are not necessarily those of
        the site owner.
      </p>
      <p>
        For inqueries or requests (e.g. changes or deletions) please reach out to{" "}
        <a href="mailto:marketing-ger@pnptc.com">marketing-ger@pnptc.com</a>.
      </p>
    </div>
  )
};


export const NotificationBar = ({className, content, icon, title, action_el}) => {
  
  return (
    <div className={className} css={css`
      background: ${color.accent_light};
      padding: 1em 1.5em;
      position: fixed;
      top: 3em;
      left: 50%;
      border-radius: 5px;
      z-index: 9999;
      transform: translateX(-50%);
      max-width: 35rem;
      width: 90%;
    `}>
      <button onClick={ action_el } css={css`
        position: absolute;
        top: 1em;
        right: 1em;
        opacity: .3;
        background: none;
        outline: none;
        cursor: pointer;
        border: none;
        padding: 0;
        line-height: 1em;
        svg {
          height: 1.2rem;
          width: 1.2rem;
          stroke: ${color.main_dark};
        }
      `}>
        <Close />
      </button>
      <div
        css={css`
          display: flex;
          align-items: baseline;
          margin-bottom: 1rem;
        `}
      >
        <div
        className="icon"
          css={css`
            padding: 0.25em 0.25rem;
            background: ${color.main_dark};
            border-radius: 5px;
            line-height: 1em;
            margin-right: .75em;
            svg {
              height: 1rem;
              width: auto;
              line-height: 1em
            }
          `}
        >
          {icon}
        </div>
        <h2
          className="title"
          css={css`
            font-weight: 700;
            font-size: 1rem;
            color: ${color.main_dark};
            line-height: 1.3em;
            text-indent: -0.05em;
            margin-bottom: 0em;
          `}
        >
          {title}
        </h2>
      </div>
      <div dangerouslySetInnerHTML={{__html: content}} css={css`
        p {
          margin-bottom: .5em;
          font-size: .75rem;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      `}/>
    </div>
  )
};

export const ExtendButton = ({className, onClick, activeState }) => {
  return (
    <button
      className={className}
      onClick={onClick}
      css={css`
        background: none;
        margin-top: 1em;
        padding: 0;
        border: none;
        display: flex;
        align-items: flex-end;
        width: 100%;
        bottom: 0;
        left: 0;
        justify-content: flex-start;
        outline: none;
        position: absolute;
        height: 5rem;
        cursor: pointer;
        transition: opacity 0.4s ease-in-out;
        background: linear-gradient(
          to bottom,
          hsla(0, 0%, 7%, 0) 0%,
          hsla(0, 0%, 7%, 0.066) 7.9%,
          hsla(0, 0%, 7%, 0.133) 14.1%,
          hsla(0, 0%, 7%, 0.203) 19%,
          hsla(0, 0%, 7%, 0.273) 23%,
          hsla(0, 0%, 7%, 0.343) 26.4%,
          hsla(0, 0%, 7%, 0.414) 29.4%,
          hsla(0, 0%, 7%, 0.484) 32.6%,
          hsla(0, 0%, 7%, 0.554) 36.1%,
          hsla(0, 0%, 7%, 0.622) 40.3%,
          hsla(0, 0%, 7%, 0.689) 45.6%,
          hsla(0, 0%, 7%, 0.753) 52.3%,
          hsla(0, 0%, 7%, 0.815) 60.8%,
          hsla(0, 0%, 7%, 0.874) 71.3%,
          hsla(0, 0%, 7%, 0.929) 84.3%,
          hsla(0, 0%, 7%, 0.98) 100%
        );
      `}
    >
      <svg
        css={css`
          stroke: white;
          height: 1em;
          transform: ${activeState ? "rotate(-90deg)" : "rotate(0)"};
          transition: transform 0.4s ease-in-out;
        `}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="9 18 15 12 9 6"></polyline>
      </svg>
      <p
        css={css`
          color: white;
          font-size: 0.75em;
          margin-bottom: 0;
          margin-left: 1em;
        `}
      >
        Read more
      </p>
    </button>
  )
}

export const Tag = ({tag, dark, className}) => {
  return (
    <div className={className} css={css`
      background: ${dark ? color.main_dark : "white"};
      color: ${dark ? color.main_light : color.main_dark};
      border-radius: 5px;
      padding: 0.75em 1.5em;
      font-size: 0.73em;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 0.07em;
      line-height: 1em;
      margin: 0.5em 0px;
      display: inline-block;
    `}>
      {tag}
    </div>
  )
};

export const SectionHeading = ({title, subtitle, className}) => {
  return (
    <div className={className}>
      {title ? (
        <h3
          css={css`
            margin: 0;
            font-size: 1em;
          `}
        >
          {title}
        </h3>
      ) : ''}
      {subtitle ? (
        <p
          css={css`
            margin: 0;
            font-size: 0.75em;
            margin-top: .35em;
          `}
        >
          {subtitle}
        </p>
      ) : ''}
    </ div>
  )
}

export const LogoCard = ({recordId, name, ...props}) => {
  let path = ""
  if (props.partner) {
    path = "/partners/" + decodeURIComponent(name
      .replace(/\./g, "")
      .replace(/&/g, "")
      .replace(/\s/g, "")
      .replace(/-/g, "")
      .replace(/é/g, "e")
    )
  } else if (props.startup) {
    path = `/${Flags.settings.protectedArea.url}/startups/` + recordId
  }

  const cardDesign = css`
    background: ${props.placeholder ? "none" : "white"};
    border-radius: 5px;
    padding: ${props.placeholder ? "0" : "1.75em 1.5em"};
    height: ${props.placeholder ? "none" : "6em"};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${props.placeholder ? "0" : ".75em"};
    flex: 0 0 calc((100% - (0.75em * 2)) / 3);
    ${mq[0]} {
      flex: 0 0 calc((100% - (0.75em * 3)) / 4);
      padding: ${props.placeholder ? "0" : "1.75em 1.25em"};
    }
    ${mq[1]} {
      flex: 0 0 calc((100% - (0.75em * 2)) / 3);
    }
    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
    }
  `

  let logo = '';
  if (props.partner) {
    logo = <PartnerLogo id={recordId} />
  } else if (props.startup) {
    logo = <StartupLogo id={recordId} />
  } else {

  }

  return (
    path && !props.placeholder ? (
      <Link
        to={path}
        css={[cardDesign, props.design]}
      >
        { logo }
      </Link>
    ) : (
      <div 
        css={[cardDesign, props.design]}
      />
    )
  )
}


